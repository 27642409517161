<template>
  <div>
    <Card>
      <template slot="outer">
        <PageTopOuter
          icon="campaign"
          label="Listening"
          :search-box="false"
          :show-dialog="false"
          class="mt-4"
          @on-create="onCreateNew"
        />
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <!-- it will be Sort by date -->
          <div class="flex items-center">
            <SelectComponent
              :placeholder="false"
              return-type="object"
              @getObject="onChangeQuestionComponent"
              class="w-64"
              :value="selectQuestionType"
              :items="[{ id: 'all', slug: 'All',name: 'All Type'}, ...questionTypes]"
            />
            <SelectComponent
              :placeholder="false"
              class="w-32 m-2"
              :items="statuses"
              return-type="index"
              @getIndex="getStatus"
            />
          </div>
          <SearchBox
            v-model="keyword"
            placeholder="Search"
            class="p-2 w-64 m-2"
            @submit="submitSearchKeyword"
            />
        </div>
        <md-divider></md-divider>
        <Table :sort-by="default_sort" :items="questions" hover-action @onSort="setSortQuestion">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
            <md-table-cell md-label="Q_ID">{{ item.q_id }}</md-table-cell>
            <md-table-cell md-sort-by="index" md-label="INDEX"> {{ item.index }} </md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-label="USED FOR" class="text-victoria ">
              <span @click="showUsedForList(item.id)" class="table-cell-link"> View all </span>
            </md-table-cell>
            <md-table-cell md-label="FREQUENCY">
              <md-icon class="m-0" :class="getIconColor(item.frequency)">{{ (item.frequency) ? 'check': '' }}
                <md-tooltip v-if="item.frequency" md-direction="top">{{ item.frequency }}</md-tooltip>
              </md-icon>
            </md-table-cell>
            <md-table-cell md-sort-by="created_at" md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
            <md-table-cell  md-sort-by="created_by" md-label="CREATED BY" class="text-victoria ">
              <span @click="showCreatedByProfile(item.creator)" class="table-cell-link">{{ item.created_by }}</span>
            </md-table-cell>
            <md-table-cell md-sort-by="active" md-label="STATUS" class="text-green">{{ (item.active == 1) ? 'Published' : 'Draft' }}</md-table-cell>
            <md-table-cell>
              <span class="action flex">
                <md-icon @click.native="onDetails(item.id)" class="bg-blue-chill rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
                <md-icon @click.native="onEdit(item.id)" class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
                <md-icon @click.native="deleteQuestion(item.id)" class="bg-tahiti-gold text-white rounded-full p-1 text-base">delete</md-icon>
              </span>
            </md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
    <div class="flex justify-end my-6">
      <Paginate
          :start="meta.from"
          :end="meta.to"
          :next="meta.current_page"
          :prev="meta.last_page"
          :total="meta.total"
          :limit="meta.per_page"
          @on-start="onStart"
          @on-end="onEnd"
      />
    </div>
    <ConfirmDialog 
      @confirm="actDeleteQuestion(questionId)"
      :active.sync="active"
    />
  </div>
</template>

<script>
import {
  Card,
  CardBody,
  SelectComponent,
  ConfirmDialog,
  PageTopOuter,
  Table,
  Paginate
  } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import { find } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { query } from "@/utils/queryString";
import cms from "@/data/cms"

export default {
  name: "SpeakingQuestionListView",
  components: {
    Card,
    Paginate,
    CardBody,
    PageTopOuter,
    ConfirmDialog,
    SelectComponent,
    SearchBox,
    Table
  },
  data() {
    return {
      active: false,
      keyword: '',
      selectedValue: null,
      pagination: true,
      frequencyStyles: cms.frequency,
      selectQuestionType: 'all',
      default_sort: 'index',
    }
  },
  computed: {
    ...mapGetters({
      questions: "question/getSectionWiseQuestion",
      statuses: "cms/getStatuses",
      meta:"question/getSectionWiseQuestionMeta",
      questionTypes: "question/getQuestionTypes",
    })
  },
  watch: {
    keyword(previous, current) {
        query.set('search', previous)
        if(previous.length < current.length && previous.length == 0) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
        if(previous.length >= 3) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
    }
  },
  methods: {
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actSectionWiseQuestion: "question/actSectionWiseQuestion",
      actGetDropdowns: "cms/actGetDropdowns",
      actDeleteQuestion: "question/actDeleteQuestion",
      actQuestionDetails: "question/actQuestionDetails",
      actGetUserInformation: "user/actGetUserInformation",
    }),
    onDetails(questionId) {
      this.$emit('on-click', {
        component: 'QuestionDetails'
      });
      this.actQuestionDetails(questionId);
    },
    onEdit(questionId) {
      this.$router.push({
        name: 'pte-question.edit',
        params: {
          id: questionId
        },
        query: {
          type: 'listening'
        }
      })
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
    onChangeQuestionComponent(item) {
      if(item.id == 'all') {
        query.remove('question_type_id');
      } else {
        query.set('question_type_id', item.id);
      }
      this.actSectionWiseQuestion(`?${query.get()}`);
      query.set('page', 1);
      this.selectQuestionType = item.id
    },
    getStatus(index) {
      this.selectedValue = this.toLower(this.statuses[index].id);
      if(this.selectedValue == 'all') {
        query.remove('active');
      }else {
        query.set('active', this.selectedValue);
      }
      this.actSectionWiseQuestion(`?${query.get()}`);
      query.set('page', 1);
    },
    submitSearchKeyword() {
      this.actSectionWiseQuestion(`?${query.get('search', this.keyword)}`);
      query.set('page', 1);
    },
    deleteQuestion(questionId) {
      this.active = true;
      this.questionId = questionId;
    },
    showCreatedByProfile(createdById) {
      this.$emit('on-click', {
        component: 'CreatedByProfile'
      });
     this.actGetUserInformation(createdById);
    },
    showUsedForList(questionId) {
      this.$emit('on-click', {
        component: 'QuestionUsedForList'
      });
      this.actQuestionDetails(questionId);
    },
    getIconColor(frequency) {
      let frequencyObject = find(this.frequencyStyles, ['id', frequency]);
      return frequencyObject ? frequencyObject.color : ''
    },
    onCreateNew() {
      this.$router.push({
        name: 'pte-question.create',
        query: {
          type: 'listening'
        }
      });
    },
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actSectionWiseQuestion(`?${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actSectionWiseQuestion(`?${query.get()}`);
    },
    setSortQuestion(sort){
      const sortString = sort.order === 'asc' ? sort.sortBy : `-${sort.sortBy}`;
      query.set('sort', sortString);
      this.actSectionWiseQuestion(`?${query.get()}`);
    },
  },
  created() {
    query.clear()
    query.set('type', 'question');
    query.set('section', 'listening');
    query.set('sort', this.default_sort);
    this.actSectionWiseQuestion(`?${query.get()}`);
    this.actGetDropdowns(`?status`);
    this.actGetQuestionTypes(`?section=listening`)
  }
};
</script>